import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import LazyImg from '~/components/lazy-img';
import FullWidthSection from '~/components/static/full-width-section';
import { reviewsRoute } from '~/routes';

import styles from '~/components/static/home/reviews-section/reviews-section.module.scss';

const ReviewsSection = () => (
  <FullWidthSection
    className={styles.reviewsSection}
    backgroundColor="var(--light-color)"
    id="homepage-reviews-section"
  >
    <div className={styles.textContainer}>
      <Link className={styles.reviewsAnchor} id="reviews" to={reviewsRoute()}>
        <h2>8,000+ reviewers are raving</h2>
      </Link>
      <p className={styles.reviewsIntro}>
        Don't just take our word for it. Take the word of{' '}
        <strong>people like you</strong> who were done with the guesswork,
        toxicity, and waste of traditional yard care. People who now have the
        confidence to do it themselves—and do it better.
      </p>
      <Link
        className={styles.allReviewsLink}
        to={reviewsRoute()}
        data-heap-id="see-all-reviews-link"
      >
        See more reviews
      </Link>
    </div>
    <div className={styles.reviewsGrid}>
      <CustomerQuote
        className={styles.mobileOnly}
        quote={`Sunday has transformed our lawn with minimal effort on our part."`}
        author="Sara, California"
        backgroundImage={
          <LazyImg src="" alt="Outline of the state of Minnesota" />
        }
      />
      <img
        className={styles.kidImage}
        srcSet="/images/home-page/young-girl-mobile.jpg"
        alt="A young blond child tends to a potted sapling before planting it."
      />
      <CustomerQuote
        className={cx(styles.desktopOnly, styles.minnesotaQuote)}
        quote={`Easy to apply. Safe for animals, people, and wildlife. Keeps my lawn looking great year round without all of the chemical junk."`}
        author="Steven, Minnesota"
        backgroundImage={
          <LazyImg
            src="/images/home-page/minnesota.svg"
            alt="Outline of the state of Minnesota"
            height={197}
            width={180}
          />
        }
      />

      <CustomerQuote
        className={cx(styles.desktopOnly, styles.tennesseeQuote)}
        quote={`Safe, easy, and effective—everyone is a lawn pro with Sunday."`}
        author="Erik, Tennessee"
      />
      <LazyImg
        className={cx(styles.desktopOnly, styles.treeImage)}
        srcSet="/images/home-page/young-tree@1x.jpg 1x, /images/home-page/young-tree@2x.jpg 2x"
        alt="A juvenile tree with red leaves grows in the middle of a freshly mowed lawn"
        height={200}
        width={589}
      />

      <CustomerQuote
        className={cx(styles.desktopOnly, styles.californiaQuote)}
        quote={`It worked great. I have had mosquitoes since living in my home for 15 years, since using this product, we can actually use our backyard."`}
        author="Lori, California"
        backgroundImage={
          <LazyImg
            src="/images/home-page/california.svg"
            alt="Outline of the state of California"
            height={197}
            width={180}
          />
        }
        variant="blue"
      />
      <CustomerQuote
        className={styles.texasQuote}
        quote={`Everything about Sunday is great...the price...the customer service...the RESULTS!"`}
        author="Troy, Texas"
        backgroundImage={
          <LazyImg
            src="/images/home-page/texas.svg"
            alt="Outline of the state of Texas"
            height={197}
            width={180}
          />
        }
        variant="yellow"
      />
      <picture className={styles.wideYardImage}>
        <source
          media="(max-width: 768px)"
          srcSet="/images/home-page/lawn-mobile@1x.jpg 1x, /images/home-page/lawn-mobile@2x.jpg 2x"
        />
        <source
          media="(min-width: 769px)"
          srcSet="/images/home-page/lawn-desktop@1x.jpg 1x, /images/home-page/lawn-desktop@2x.jpg 2x"
        />
        <img
          srcSet="/images/home-page/lawn-desktop@1x.jpg 1x, /images/home-page/lawn-desktop@2x.jpg 2x"
          alt="A well-groomed, freshly mowed lawn surrounded by privacy shrubs and a garden"
        />
      </picture>
      <ArticleLink
        className={styles.pureWowLink}
        href="https://www.purewow.com/home/sunday-lawn-care-review"
        data-heap-id="pure-wow-link"
      >
        <LazyImg src="/images/home-page/pure-wow.png" alt="PureWow logo" />
        <p className={styles.articleQuote}>Definitely worth the investment."</p>
      </ArticleLink>

      <CustomerQuote
        className={styles.mobileOnly}
        quote={`Works great! No mosquitoes in my yard."`}
        author="Ottavio, Illinois"
        variant="blue"
      />
      <LazyImg
        className={cx(styles.beforeAfterImage, styles.desktopOnly)}
        alt="Before and after shot of a lawn"
        srcSet="/images/home-page/reviews-before-after-590w.jpg 590w, /images/home-page/reviews-before-after-1180w.jpg 1180w"
      />
      <ArticleLink
        className={styles.mobileOnly}
        href="https://todayshomeowner.com/lawn-garden/reviews/lawn-care-companies/"
        data-heap-id="todays-homeowner-link"
      >
        <p className={styles.articleQuote}>Best DIY Lawn Care Program."</p>
        <p className={styles.articleSource}>&mdash; Today's Homeowner</p>
      </ArticleLink>
    </div>
  </FullWidthSection>
);

export default ReviewsSection;

const CustomerQuote = ({
  quote,
  author,
  backgroundImage,
  className,
  variant,
}) => {
  return (
    <div className={cx(styles.customerQuote, styles[variant], className)}>
      <p className={styles.quoteText}>{quote}</p>
      <p className={styles.quoteAuthor}>{author}</p>
      {backgroundImage}
    </div>
  );
};

const ArticleLink = ({ href, children, className, ...rest }) => {
  return (
    <a
      className={cx(styles.articleLink, className)}
      href={href}
      target="blank"
      {...rest}
    >
      <div className={styles.articleLinkInner}>{children}</div>
    </a>
  );
};
